const translations = {
	Product: {
		categoriesNotFound: 'No se han encontrado categorías.',
		title: 'En Categorías',
		notFound: 'No se han encontrado productos',
		textNotFound: 'Pulsa enter para una búsqueda más exhaustiva.',
		lastUnits: '¡Últimas unidades!',
		noStock: 'Sin existencias',
		ref: 'Ref:',
		stock: 'En stock',
		symbol: '€',
	},
	Moto: {
		nature: {
			KM0: 'KM0',
			NUEVA: 'NUEVA',
			OCASION: 'OCASIÓN',
			SEMINUEVA: 'SEMINUEVA',
		},
		title: 'En Motos',
		info1: 'Garantía de',
		info2: 'meses | Carnet:',
	},
	SearchBar: {
		placeholder: 'Buscar en Tienda Online de Motos',
	},
	Sections: [
		{
			value: '',
			name: 'Todas las secciones',
		},
		{
			value: 'accesorios_recambios',
			name: 'Accesorios y recambios',
		},
		{
			value: 'BOU',
			name: 'Boutique',
		},
		{
			value: 'escapes',
			name: 'Sistemas de escapes',
		},
		{
			value: 'lubricantes',
			name: 'Lubricantes',
		},
		{
			value: 'motos',
			name: 'Motos',
		},
		{
			value: 'NEU',
			name: 'Neumáticos',
		},
	],
};

const useTranslation = () => {
	const t = (path) => {
		const separatedPath = path.split('.');

		let res = '';

		for (let i = 0; i < separatedPath.length; i++) {
			if (res === '') {
				res = translations[separatedPath[i]];
			} else {
				res = res[separatedPath[i]];
			}
		}

		return res;
	};

	return { t };
};

export { useTranslation };
