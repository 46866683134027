//#region MÓDULOS
import { useTranslation } from '../../translations';
import CSS from './Sections.module.css';

/** Type Definitions
 * @typedef {{ name: string; value: string }} Section
 * @typedef {{ onChange: (section: Section) => {} }} SectionsProps
 */
//#endregion

const Sections = (props) => {
	//#region VARIABLES
	const { onChange } = props;
	const { t } = useTranslation('Search');
	const sections = t('Sections', { returnObjects: true });
	//#endregion

	//#region FUNCIONES
	const onChangeHandler = (e) => {
		let selected = sections.find((s) => s.value === e.target.value);
		onChange(selected);
	};
	//#endregion

	//#region RENDER
	return (
		<select defaultValue={'todos'} onChange={onChangeHandler} className={CSS.select}>
			{sections.map((section, i) => (
				<option key={`${section.value}_${i}`} value={section.value} className={CSS.option}>
					{section.name}
				</option>
			))}
		</select>
	);
	//#endregion
};

Sections.displayName = 'Sections';
export default Sections;
