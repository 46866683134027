//#region MÓDULOS
import React, { useEffect, useState } from 'react';
import CSS from './Autocomplete.module.css';
import { useTranslation } from '../../translations';
import { site, changeExtension, controlUrl } from '../../globals';

/** Type Definitions
 * @typedef {{ name: string; value: string }} Section
 * @typedef {{ API: (method: string, params: {}) => Promise<Response>; parent: HTMLElement | null; section: Section; value: string; visibilityHandler: (show: boolean) => {}; }} AutocompleteProps
 */
//#endregion

//#region VARIABLES ESTÁTICAS
const defaultSuggests = {
	bicis: [],
	cats: [],
	fabs: [],
	motos: [],
	prods: [],
	show: false,
};

const onSearchBlurHandler = (e, visibilityHandler) => {
	let content = document.querySelectorAll('#search-suggest, #search-suggest *'),
		links = document.querySelectorAll('#search-suggest a, #search-suggest a *');
	let block = Array.from(content),
		blockLinks = Array.from(links);

	if (!block.includes(e.target) || blockLinks.includes(e.target)) {
		visibilityHandler(false);
	}
};
//#endregion

/**@param {AutocompleteProps} props*/
const Autocomplete = (props) => {
	//#region VARIABLES
	const { API, parent, section, value, visibilityHandler = () => {} } = props;
	const validValue = value.trim() > '' && value.trim().length >= 2;
	const [suggests, setSuggests] = useState(defaultSuggests);
	const { t } = useTranslation('Search');
	//#endregion

	//#region FUNCIONES
	useEffect(() => {
		const binded = (e) => onSearchBlurHandler(e, visibilityHandler);
		if (typeof document !== 'undefined') {
			document.addEventListener('click', binded);
		}

		return () => document.removeEventListener('click', binded);
	});

	useEffect(() => {
		let timeout = null;
		if (!validValue) {
			setSuggests({});
		} else {
			timeout = setTimeout(() => {
				const search = { bus: value };
				if (section !== '') {
					search.section = section;
				}
				API('getSearch', search)
					.then((data) => {
						setSuggests({ ...data, show: true });
					})
					.catch((error) => {
						setSuggests(defaultSuggests);
						console.error(error);
					});
			}, 500);
		}

		return () => clearTimeout(timeout);
	}, [parent, value, validValue]);
	//#endregion

	//#region PRE-RENDER
	const naturalezas = [...new Set(suggests?.motos?.map((moto) => moto.nat))] || [];
	const availableProducts = suggests.prods?.slice(0, naturalezas?.length > 0 ? 3 : 6) || [];
	const availableMotos = suggests.motos?.slice(0, 3) || [];
	const availableCategoriesProps = suggests.cats?.slice(0, naturalezas?.length > 0 ? 4 : 8) || [];
	const availableCategoriesMotos = naturalezas?.slice(0, 4) || [];
	const hasResults = availableProducts.length > 0 || availableMotos.length > 0 || availableCategoriesProps.length > 0;
	//#endregion

	//#region RENDER
	return validValue && suggests && suggests.show ? (
		<div id="search-suggest" className={`${CSS.container} ${CSS[site.tag]}`}>
			<div className={CSS.content}>
				{hasResults ? (
					<>
						{(availableProducts.length > 0 || availableCategoriesProps.length > 0) && (
							<>
								<h3>{t('Product.title')}</h3>
								<div className={CSS.results}>
									<div className={CSS.filters}>
										{availableCategoriesProps.length > 0 ? (
											<div className={CSS.wrapperCategories}>
												{availableCategoriesProps?.map((catalogue) => {
													const { shop, url_catalogo, url_categoria, url_subcategoria, url_fabricante } = catalogue;

													let href = '';
													if (shop) href += `/${shop}`;
													if (url_catalogo) href += `/${url_catalogo}`;
													if (url_categoria) href += `/categoria-${url_categoria}`;
													if (url_subcategoria) href += `/familia-${url_subcategoria}`;
													if (url_fabricante) href += `/fabricante-${url_fabricante}`;

													return (
														<a className={CSS.categories} key={catalogue.id} href={href}>
															{`${catalogue.cat} - ${catalogue.sub}`}
														</a>
													);
												})}
											</div>
										) : (
											<span className={CSS.notFound}>{t('Product.categoriesNotFound')}</span>
										)}
									</div>
									<div className={CSS.products}>
										{availableProducts.map((product) => {
											const stock = product.stock > 0 ? (product.stock > 4 ? t('Product.stock') : t('Product.lastUnits')) : t('Product.noStock');
											return (
												<a href={product.url?.startsWith('/') ? product.url : `/${product.url}`} key={product.des} className={CSS.item}>
													<img
														className={CSS.img}
														src={controlUrl(product.image)}
														alt={product.des}
														onError={({ currentTarget }) => {
															currentTarget.onerror = null;
															currentTarget.src = `${site.sources.url}/static/img/no_img.webp`;
														}}
													/>
													<div>
														<p className={CSS.title}>{product.des}</p>
														{product?.sale ? (
															<div>
																<p className={CSS.info}>
																	{`${product.fab} | ${stock} - `}
																	<del>{product?.salePrice + t('Product.symbol') + ' '}</del>
																</p>
																<span className={CSS.newPrice}>{product?.price + t('Product.symbol') + ' '}</span>
																<span className={CSS.sale}>{product?.discountPercent}%</span>
															</div>
														) : (
															<p className={CSS.info}>{`${product.fab} | ${stock} - ${product.price + t('Product.symbol')}`}</p>
														)}
														<em className={CSS.ref}>
															{t('Product.ref')} {product.ref}
														</em>
													</div>
												</a>
											);
										})}
									</div>
								</div>
							</>
						)}
						{naturalezas.length > 0 && (
							<div className={CSS.containerMoto}>
								{(availableProducts.length > 0 || availableCategoriesProps.length > 0) && <hr className={CSS.separator} />}
								<h3>{t('Moto.title')}</h3>
								<div className={CSS.results}>
									<div className={CSS.filters}>
										<div className={CSS.wrapperMotos}>
											{availableCategoriesMotos?.map((nat, index) => {
												return (
													<a className={CSS.btn} href={`/motos/${nat.toLowerCase()}`} key={index}>
														{t('Moto.nature', { returnObjects: true })[nat].toLowerCase()}
													</a>
												);
											})}
										</div>
									</div>
									<div className={CSS.products}>
										{availableMotos.map((moto) => {
											return (
												<a key={moto.url} href={moto.url?.startsWith('/') ? moto.url : `/${moto.url}`} className={CSS.moto}>
													<img
														className={CSS.img}
														src={controlUrl(moto.image)}
														alt={moto.des}
														onError={({ currentTarget }) => {
															currentTarget.onerror = null;
															currentTarget.src = controlUrl('/static/img/no_img.webp');
														}}
													/>
													<div>
														<p className={CSS.title}>{moto.des}</p>
														<p>
															{t('Moto.info1')} {moto.gar} {t('Moto.info2')} {moto.carnet}
														</p>
													</div>
												</a>
											);
										})}
									</div>
								</div>
							</div>
						)}
						<div className={CSS.brands}>
							{suggests?.fabs.map((fab) => {
								return <Brand key={fab.id} fab={fab} />;
							})}
						</div>
					</>
				) : (
					<>
						<h3>{t('Product.notFound')}</h3>
						<span className={CSS.notFound}>{t('Product.textNotFound')}</span>
					</>
				)}
			</div>
		</div>
	) : (
		<></>
	);
	//#endregion
};
//#endregion

//#region AUXILIARES
const Brand = (props) => {
	const { fab } = props;
	const [showImg, setShowImg] = useState(true);

	let href = '';
	if (fab.shop) href += `/${fab.shop}`;
	if (fab.url) href += `/${fab.url}`;

	return (
		<a href={href}>
			{showImg && fab.ima ? (
				<img
					className={CSS.img}
					src={`${site.sources.cdn}/wp-content/marcas/${changeExtension(fab.ima, 'webp')}`}
					alt={fab.id}
					title={fab.id}
					onError={() => setShowImg(false)}
				/>
			) : (
				<span className={CSS.nameBrand}>{fab.id.toUpperCase()}</span>
			)}
		</a>
	);
};
//#endregion

Autocomplete.displayName = 'Autocomplete';
export default Autocomplete;
