//#region MÓDULOS
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from '../translations';
import Autocomplete from './autocompletion/Autocomplete';
import Sections from './autocompletion/Sections';
import CSS from './index.module.css';

/** Type Definitions
 * @typedef {{ autocompletion: boolean; cdn: string; env: "DEV" | "PRO"; url: string; }} SearcherConfig
 * @typedef {{ config: SearcherConfig }} SearcherProps
 */
//#endregion

/**@param {SearcherProps} props*/
const Searcher = (props) => {
	//#region VARIABLES
	const { config } = props;

	const { t } = useTranslation('Search');
	const sections = t('Sections', { returnObjects: true });

	const parent = useRef(null);
	const [value, setValue] = useState('');
	const [selectedSection, setSelectedSection] = useState(sections[0]);
	const [suggets, setSuggests] = useState(false);
	const { core, env, url, tag } = config;
	//#endregion

	//#region FUNCIONES
	const API = useCallback(
		async (method = '', params = {}) => {
			const body = JSON.stringify({
				command: 'webEcommerce',
				core: core,
				env: env,
				method: method,
				...params,
			});

			const response = await fetch(`${url}/API`, {
				body: body,
				credentials: 'include',
				method: 'POST',
			});

			return response.json();
		},
		[core, env, url]
	);

	function allowedValue(value = '') {
		return value?.trim() !== '' && value?.length >= 2;
	}

	function onChangeHandler(e) {
		setValue(e.target.value);
		if (config.autocompletion) {
			setSuggests(true);
		}
	}

	function onKeyDownHandler(e) {
		if (e.key && e.key === 'Enter') {
			doSearch();
		}
	}

	function doSearch() {
		if (allowedValue(value)) {
			const query = `?keyword=${value}`;

			window.location.href = `/search${query}`;
		}
	}
	//#endregion

	//#region RENDER
	return (
		<div className={CSS[tag]}>
			<div ref={parent} className={CSS.search}>
				{config.sections && <Sections onChange={(section) => setSelectedSection(section)} />}
				<input
					data-sections={config.sections}
					className={CSS.input}
					onChange={onChangeHandler}
					onKeyDown={onKeyDownHandler}
					type="text"
					value={value}
					placeholder={t('SearchBar.placeholder')}
				/>
				<button className={CSS.button} onClick={doSearch}>
					<img width={18} height={18} alt="search" src={config.cdn + (tag === 'LOLA' ? '/wp-content/icons/otros/lupa-gray.svg' : '/wp-content/icons/otros/lupa-buscador-elm.svg')} />
				</button>
			</div>
			{suggets && (
				<div className={CSS.containerAutocomplete}>
					<Autocomplete API={API} parent={parent.current} section={selectedSection.value} value={value} visibilityHandler={setSuggests} />
				</div>
			)}
		</div>
	);
	//#endregion
};

Searcher.displayName = 'Searcher';
export default Searcher;
