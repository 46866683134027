import React from 'react';
import ReactDOM from 'react-dom/client';
import Searcher from './searcher';
import { site } from './globals';

const elements = document.querySelectorAll('#buscador');
const config = {
	cdn: site.sources.cdn,
	env: site.env,
	url: site.sources.url,
	tag: site.tag,
	autocompletion: true,
	core: site.core,
};

for (let i = 0; i < elements.length; i++) {
	const element = elements[i];
	const root = ReactDOM.createRoot(element);
	root.render(
		<React.StrictMode>
			<Searcher config={config} />
		</React.StrictMode>
	);
}
